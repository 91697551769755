
import { IVersionInfo } from "../../domain/versions/IVersionInfo";
import { IVersionInfoRepository } from "../../domain/versions/IVersionInfoRepository";
import { appDependencies } from "../../domain/wiring/AppDependencies";

export class VersionInfoRepository implements IVersionInfoRepository {
    private _versionInfo: IVersionInfo | null = null;

    async getVersionInfo(): Promise<IVersionInfo> {
        if (!this._versionInfo) {
            const webApiVersion = await appDependencies.IRestApi.request("/api/version").getData<{ version: string, environment: string }>();

            let version = "1.0.25043.1";

            if (version.charAt(0) === "$") {
                version = "1.0.0";
            }

            this._versionInfo = {
                webApiVersion: webApiVersion.version,
                appVersion: version,
                environment: webApiVersion.environment
            };
        }
        return Promise.resolve(this._versionInfo);
    }
}

